import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'gatsby';
import './blog-card.css';
import moment from 'moment';
import { sliceByWord } from '../../utils/sliceByWord';
import { blog } from '../blog/blog.css';
import { BlogPostI } from '../blog/BlogPost.interface';
import { replaceHtmlEntities } from '../../utils/replaceHtmlEntities';
import BlogCard from '../../components/layout/newBlogCard/BlogCard';
import { NewStandardPageLayout } from '../../layouts/newStadardPage.layout';
import { MetaObjectI } from '../../meta/Head.component';
import { css } from '@emotion/core';
import {
  BadgeGray,
  BadgeOrange,
  BigTitle,
  FlexCol,
  FlexWrapper,
  FlexWrapperStart,
  SmallTitle,
  Wrapper,
} from '../../global/new-global-components';
import { FeaturedPostImage, FeaturedPostLink, FeaturedPostText } from '../../pages_/home/new_press_section/press.styles';
import { BlogPageWrapper } from './new-blog-page.styles';

interface BlogFeedPropsI {
  type: string;
  posts: Array<BlogPostI>;
  meta?: MetaObjectI;
  featuredPostsTag?: string;
  featuredPostsTitle?: string | undefined;
}

const getCustomDate = (date: string): string => {
  const dateTimeFormat = new Intl.DateTimeFormat('sr-RS', { year: 'numeric', month: 'numeric', day: '2-digit' });
  return dateTimeFormat.format(new Date(date)).replace(/\//g, '.');
};

export const BlogFeedPage: React.FC<BlogFeedPropsI> = ({ featuredPostsTag, featuredPostsTitle, posts, meta, type }) => {
  const { t } = useTranslation();

  const firstPostTitle = posts[0] ? posts[0].title : (t('noBlogPosts') as string);

  function compare(a: any, b: any) {
    if (a.date > b.date) {
      return -1;
    }
    if (a.date < b.date) {
      return 1;
    }
    return 0;
  }

  posts.sort(compare);

  // if (!firstPostFeaturedImg) {
  //   firstPostFeaturedImg = getHeroPlaceholderBackground();
  // }

  const imgContainer = css`
    max-height: 360px;
    overflow: hidden;
    border-radius: 22px;
    width: 50%;
    margin-top: 20px;
    margin-bottom: 30px;

    @media screen and (max-width: 800px) {
      width: 100%;
    }
  `;

  const featuredPostTitle = css`
    font-size: 26px;
    font-weight: 800;

    @media screen and (max-width: 800px) {
      margin: 10px 0;
      font-weight: 900;
      font-size: 20px;
      line-height: 1.2;
    }
  `;

  const featuredPostDescription = css`
    font-size: 16px;
    font-weight: 300;

    @media screen and (max-width: 800px) {
      margin-bottom: 20px;
    }
  `;

  const featuredPostDate = css`
    font-size: 14px;
    font-weight: 300;
  `;

  const featuredPostContainer = css`
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 800px) {
      display: block;
    }
  `;

  const textContainer = css`
    width: 50%;
    padding-left: 30px;

    @media screen and (max-width: 800px) {
      width: 100%;
      padding: 0;
    }
  `;

  const linkStyle = css`
    color: inherit;
  `;

  const types = {
    news: {
      backgroundImage: 'blog/header-news-page.jpg',
      backgroundImageMobile: 'blog/header-news-page-mobile.jpg',
    },
    promo: {
      backgroundImage: 'blog/header-promo.jpg',
      backgroundImageMobile: 'blog/header-promo-mobile.jpg',
    },
    blog: {
      backgroundImage: 'blog/blog-page-header.jpg',
      backgroundImageMobile: 'blog/blog-header-mobile.png',
    },
  } as any;

  return (
    <NewStandardPageLayout
      backgroundImage="/new-images/bundles-page-new.png"
      backgroundImageMobile="/new-images/bundles-page-new-mobile.png"
      backgroundColor="#F4F4F4"
      meta={meta}
    >
      <BlogPageWrapper>
        <Wrapper className="blog-page">
          <BigTitle fontWeight="800" marginBottom="80px" color="white">
            {type === 'blog' ? 'VOZZi blog' : type === 'promo' ? 'VOZZi promo' : type === 'news' ? 'VOZZi news' : null}
          </BigTitle>
          {/* <Text textAlign="center" marginBottom="80px" fontSize="26px" color="white">
            {t('home_BlogFeaturedHeading')}
          </Text> */}

          {!!posts.length && (
            <>
              <Wrapper className="featured-post-wrapper">
                <Link to={posts[0].url}>
                  <FlexWrapper
                    className="mobile-flex-reverse featured-post"
                    overflow="hidden"
                    backgroundColor="#1e1e23"
                    borderRadius="20px"
                  >
                    <FlexCol className="flex-col flex-col-text" width="45%" padding="20px 40px">
                      <FlexWrapperStart className="mobile-stay-flex">
                        <BadgeOrange>{posts[0].categories[0].slug}</BadgeOrange>
                        <BadgeGray>{moment(posts[0].date).format('DD.MM.YYYY')}</BadgeGray>
                      </FlexWrapperStart>
                      <SmallTitle textAlign="left" marginBottom="15px" color="white" fontWeight="800" fontSize="28px">
                        {replaceHtmlEntities(posts[0].title)}
                      </SmallTitle>
                      <FeaturedPostText
                        dangerouslySetInnerHTML={{ __html: sliceByWord(posts[0].excerpt as string, 200).replace(/<[^>]*>?/gm, '') }}
                      ></FeaturedPostText>
                      <FeaturedPostLink style={{ marginTop: 15 }}>{t('blogReadAll')}</FeaturedPostLink>
                    </FlexCol>
                    <FlexCol className="flex-col" width="55%">
                      <Wrapper>
                        <FeaturedPostImage src={posts[0].featuredImg.src} />
                      </Wrapper>
                    </FlexCol>
                  </FlexWrapper>
                </Link>
              </Wrapper>

              <Wrapper className="posts">
                {posts
                  .filter((post) => !post.tags || post.tags[0].name !== featuredPostsTag || post.categories[0].slug === 'promo')
                  .map((post: BlogPostI, index: number) => (
                    <React.Fragment key={index}>
                      {index !== 0 ? (
                        <BlogCard
                          className="blog-card"
                          category={post.categories[0].slug}
                          excerptClassName="excerpt"
                          key={post.id}
                          image={post.featuredImg}
                          date={getCustomDate(post.date)}
                          title={replaceHtmlEntities(post.title)}
                          excerpt={post.excerpt}
                          link={post.url}
                        />
                      ) : null}
                      {posts.length === 1 && type === 'blog' ? (
                        <BlogCard
                          className="blog-card"
                          category={post.categories[0].slug}
                          excerptClassName="excerpt"
                          key={post.id}
                          image={post.featuredImg}
                          date={getCustomDate(post.date)}
                          title={replaceHtmlEntities(post.title)}
                          excerpt={post.excerpt}
                          link={post.url}
                        />
                      ) : null}
                    </React.Fragment>
                  ))}
              </Wrapper>
            </>
          )}
        </Wrapper>
      </BlogPageWrapper>
    </NewStandardPageLayout>
  );
};
