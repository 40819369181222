import React, { ReactElement } from 'react';
import { graphql } from 'gatsby';

import { COUNTRY } from '../../configs/env';
import { countryLangs } from '../../configs/country-langs';
import { WpFeedDataPropsI, WpPostNodeI } from '../../types/WpFeedData';
import { getFeaturedImgForBlogLayout } from '../../helpers/getFeaturedImgForBlogLayout';
import { BlogFeedPage } from '../blog/new-blog-feed.page';
import { BlogPostI } from '../blog/BlogPost.interface';
import { useTranslation } from 'react-i18next';

const NewsPage = (props: WpFeedDataPropsI): React.FC | ReactElement => {
  const { t } = useTranslation();

  const meta = {
    title: t('meta_NovostiTitle'),
    description: t('meta_NovostiDescription'),
  };

  const returnWpPostsByCountry = (edge: WpPostNodeI) => {
    if (COUNTRY === 'rs') {
      return edge.node.country === COUNTRY || !edge.node.country;
    } else {
      return edge.node.country === COUNTRY;
    }
  };

  const cLangs: any = countryLangs;

  const posts: Array<BlogPostI> = props.data.allWordpressPost.edges
    .filter(
      (edge: WpPostNodeI) => edge.node.categories.filter((category) => category.slug === 'news' || category.slug === 'press').length > 0
    )
    .filter((edge: WpPostNodeI) => returnWpPostsByCountry(edge))
    .map((edge: WpPostNodeI) => {
      return {
        id: edge.node.id,
        title: edge.node.title,
        excerpt: edge.node.excerpt,
        featuredImg: getFeaturedImgForBlogLayout(edge.node),
        date: edge.node.date,
        url: `/${cLangs[COUNTRY]}/${t('news_Route')}/${edge.node.slug}`,
        categories: edge.node.categories,
      };
    });

  return <BlogFeedPage type="news" posts={posts} meta={meta} />;
};

export const allPostsQuery = graphql`
  query {
    allWordpressPost {
      edges {
        node {
          id
          slug
          path
          title
          excerpt
          status
          date
          template
          country
          format
          categories {
            slug
          }
          featured_media {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default NewsPage;
