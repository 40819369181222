export const replaceHtmlEntities = (text: string): string => {
  return text
    .replace('&amp;', '&')
    .replace('&gt;', '>')
    .replace('&lt;', '<')
    .replace('&quot;', '"')
    .replace('&#039;', "'")
    .replace('&#8211;', '-')
    .replace('&#8230;', '…');
};
