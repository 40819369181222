import React from 'react';
import Img, { FluidObject as GatsbyImageFluid } from 'gatsby-image';
import './blog-card.css';

import { Text } from 'rebass';
import { Link } from 'gatsby';
import { css } from '@emotion/core';
import { BadgeGray, BadgeLightGray, FlexCol, SmallTitle, Wrapper } from '../../../global/new-global-components';

interface BlogCardProps {
  image: GatsbyImageFluid;
  date?: string;
  title: string;
  excerpt?: string;
  link: string;
  category?: string;
  className?: string;
  excerptClassName?: string;
}

const imgContainer = css`
  max-height: 220px;
  overflow: hidden;
  border-top-left-radius: 22px;
  border-top-right-radius: 22px;
`;

const imagestyle = css`
  max-width: 101%;
  width: 640px;
  height: 210px;
`;

const BlogCard: React.FC<BlogCardProps> = ({ image, category, date, title, excerpt, link }) => {
  return (
    <FlexCol className="blog-card-wrapper" width="33.3333%" padding="20px 30px">
      <Link to={link}>
        <Wrapper className="post-card">
          <Wrapper className="imgContainer" css={imgContainer}>
            <Img fluid={image} css={imagestyle} />
          </Wrapper>
          <Wrapper className="post-text-wrapper">
            <BadgeLightGray display="inline-block">{category}</BadgeLightGray>
            <BadgeGray display="inline-block">{date}</BadgeGray>
            <SmallTitle textAlign="left" fontSize="20px">
              {title}
            </SmallTitle>
            <Text fontSize="16px" dangerouslySetInnerHTML={{ __html: excerpt as string }}></Text>
          </Wrapper>
        </Wrapper>
      </Link>
    </FlexCol>
  );
};

export default BlogCard;
