import styled from '@emotion/styled';

export const BlogPageWrapper = styled.div`
  .blog-page {
    padding: 120px 250px;

    .post-card {
      box-shadow: 1px 45px 45px rgb(0 0 0 / 10%);
    }

    .featured-post {
      border-radius: 22px;
    }

    @media screen and (max-width: 650px) {
      .posts {
        .blog-card-wrapper {
          width: 100% !important;
          flex: 0 0 100% !important;
        }
      }
    }

    @media screen and (min-width: 651px) and (max-width: 800px) {
      .post-card {
        h3 {
          max-height: 90px !important;
          min-height: 90px !important;
        }
      }
    }

    @media screen and (min-width: 780px) and (max-width: 900px) {
      .featured-post {
        flex-wrap: wrap;
        flex-direction: column-reverse;

        .flex-col {
          width: 100%;
        }

        .flex-col-text {
          padding: 20px;
        }
      }
      .posts {
        .blog-card-wrapper {
          width: 50%;
          flex: 0 0 50%;
          padding: 10px;
        }
      }
    }

    @media screen and (max-width: 1024px) {
      .posts {
        .blog-card-wrapper {
          width: 50%;
          flex: 0 0 50%;
        }
      }
    }

    @media screen and (min-width: 1025px) and (max-width: 1125px) {
      .post-card {
        h3 {
          max-height: 85px !important;
          min-height: 85px !important;
        }
      }
    }

    @media screen and (min-width: 1200px) and (max-width: 1220px) {
      .post-card {
        h3 {
          max-height: 85px !important;
          min-height: 85px !important;
        }
      }
    }

    @media screen and (min-width: 1400px) and (max-width: 1616px) {
      .post-card {
        h3 {
          max-height: 100px !important;
          min-height: 100px !important;
        }
      }
    }

    @media screen and (min-width: 2200px) {
      .post-card {
        h3 {
          max-height: 95px !important;
          min-height: 95px !important;
        }
      }
    }

    @media creen and (min-width: 1600px) {
      padding: 120px 200px;
    }
    .featured-post-wrapper {
    }
    .posts {
      display: flex;
      flex-wrap: wrap;
      padding-top: 60px;

      a {
        color: inherit;
      }
    }

    .post-card {
      background-color: white;
      border-radius: 22px;
      p {
        max-height: 70px;
        min-height: 70px;
        overflow: hidden;
      }

      .imgContainer {
        border-top-left-radius: 22px;
        border-top-right-radius: 22px;
      }

      .post-text-wrapper {
        padding: 20px 30px 40px 30px;
      }
      h3 {
        max-height: 65px;
        min-height: 65px;
        margin-bottom: 20px;
      }
    }

    @media screen and (max-width: 768px) {
      padding: 20px;

      .flex-col {
        width: 100%;
      }

      .flex-col-text {
        padding: 20px;
      }

      .blog-card-wrapper {
        width: 100%;
        padding: 0;
        margin-bottom: 20px;
      }
    }
  }

  @media screen and (min-width: 450px) and (max-width: 769px) {
    .posts {
      .blog-card-wrapper {
        padding: 1%;
        margin-bottom: 0;
        margin-bottom: 20px;
      }
    }
    .blog-page {
      padding: 60px 50px;
    }
  }

  @media screen and (min-width: 900px) and (max-width: 1199px) {
    .blog-card-wrapper {
      padding: 10px 10px;
    }

    .flex-col-text {
      padding: 20px;
    }
  }

  @media screen and (min-width: 769px) and (max-width: 1399px) {
    .blog-page {
      padding: 120px 50px;
    }
    .blog-page {
      .post-card {
        h3 {
          font-size: 20px;
        }

        p {
          font-size: 14px;
          max-height: 80px;
          min-height: 80px;
        }
      }
    }
  }
`;
